<template>
  <div :key="updateView">
    <el-alert v-if="hasEditedFields" type="warning" show-icon style="margin-bottom: 15px" :closable="false"> Ci sono
      modifiche non salvate
    </el-alert>
    <el-card class="box-card">
      <el-descriptions v-if="currentApartment" class="margin-top table-fixed"
                       :title="'Info Appartamento - ' + getCurrentTenantName(currentApartment)" :column="3"
                       direction="vertical" border>
        <template #extra>
          <el-button v-if="hasEditedFields" type="primary" @click="saveEdits"><i
              class="el-icon-upload"></i> Salva Modifiche
          </el-button>
        </template>
        <el-descriptions-item v-for="apartment in apartmentDescriptionFields" :key="apartment.field"
                              :span="apartment.span">
          <template #label>
            <div class="justify-space-between" style="display: flex; align-items: flex-end">
              <span>
                <i :class="apartment.icon"></i> {{ apartment.label }}
              </span>
              <span style="float: right"
                    v-if="apartment.editable && (isGranted('ROLE_MANAGER') ||  (isOwner(currentUser, currentCondominium) || isCondominiumAdministrator(currentUser, currentCondominium)) || isSupplier(currentUser, currentCondominium))"
                    @click="apartment.onEdit(apartment)"
                    class="pointer edit-button"><i class="el-icon-edit"></i>Modifica</span>
            </div>
          </template>
          {{ apartment.value }}
          <el-tooltip v-if="apartment.field==='relativeConsumption'" class="item" effect="dark"
                      :content="'Scostamento da consumo ideale: ' + (difference).toFixed(2) + '%'">
            <i :class="getIcon(difference/100)"></i>
          </el-tooltip>
        </el-descriptions-item>
      </el-descriptions>
      <simple-input-dialog ref="simpleInputDialog" @submit="updateCurrentField"></simple-input-dialog>
      <simple-input-dialog ref="simpleKDialog" @submit="updateCurrentField"></simple-input-dialog>
      <change-user-dialog v-if="isGranted('ROLE_MANAGER')" ref="simpleSelectDialog"
                          @submit="updateCurrentSelect"></change-user-dialog>
      <change-tenant-dialog v-if="isGranted('ROLE_MANAGER') || isOwner(currentUser, currentCondominium)"
                            ref="changeTenantDialog" @submit="updateTenant"></change-tenant-dialog>
    </el-card>
    <p></p>
    <el-card class="box-card">
      <el-button type="primary" @click="$router.push('/dashboard/letture/'+currentApartment.id)">
        {{ $t("buttons.apartment.showReadings") }}
      </el-button>
      <el-button v-if="isGranted('ROLE_MANAGER')" type="primary" @click="openUserHistoryDialog"><span
          class="el-icon-refresh-left"></span> {{ $t("buttons.apartment.showUserHistory") }}
      </el-button>
    </el-card>
    <p></p>
    <el-card v-if="currentApartment" class="box-card">
      <meter-list></meter-list>
    </el-card>
    <apartment-user-history-dialog ref="userHistoryDialog"></apartment-user-history-dialog>

  </div>
</template>

<script>
import UserMixin from "../../mixins/UserMixin";
import AddressMixin from "../../mixins/AddressMixin";
import IconsMixin from "../../mixins/IconsMixin";
import UtilsMixin from "../../mixins/UtilsMixin";
import SimpleInputDialog from "../../components/Dialogs/SimpleInputDialog";
import {mapActions, mapGetters} from "vuex";
import MeterList from "./MeterList";
import ChangeTenantDialog from "../../components/Dialogs/ChangeTenantDialog";
import ApartmentUserHistoryDialog from "../../components/Dialogs/ApartmentUserHistoryDialog";
import moment from "moment";


export default {
  name: "Apartment",
  mixins: [UserMixin, AddressMixin, IconsMixin, UtilsMixin],
  components: {ApartmentUserHistoryDialog, SimpleInputDialog, MeterList, ChangeTenantDialog},
  data() {
    return {
      apartmentDescriptionFields: [],
      hasEditedFields: false,
      editedFields: {},
      currentField: null,
      newMeterFields: [],
      difference: 0,
      consumption: 0,
      relativeConsumption: 0,
      targetConsumption: 0,
      updateView: false,
    }
  },
  computed: {
    ...mapGetters(["currentUser", "currentCondominium", "basicUserList", "currentApartment", "appUser", "isGranted", "condominiumAdministratorList"]),
  },
  methods: {
    ...mapActions(["fetchUser", "fetchApartment", "fetchAllCondominiumAdministrators", "updateApartment", "fetchAllBasicUsers", "fetchCondominium", "fetchMeter"]),
    editField(field) {
      this.currentField = field;
      this.$refs.simpleInputDialog.title = field.label;
      this.$refs.simpleInputDialog.formData = field.value;
      this.$refs.simpleInputDialog.show();
    },
    editK(field) {
      this.currentField = field;
      this.$refs.simpleKDialog.title = field.label;
      this.$refs.simpleKDialog.formData = field.value;
      this.$refs.simpleKDialog.type = "number"
      this.$refs.simpleKDialog.show();
    },
    updateCurrentField(data) {
      this.hasEditedFields = true;
      this.editedFields[this.currentField.field] = data;
      this.currentField.value = data;
    },
    async editUser(field) {
      this.currentField = field;
      this.$refs.simpleSelectDialog.formData = field.value;
      this.$refs.simpleSelectDialog.show();
    },
    async editTenant(field) {
      this.currentField = field;
      this.$refs.changeTenantDialog.currentTenant = this.getCurrentTenant(this.currentApartment);
      this.$refs.changeTenantDialog.show();
    },
    async updateCurrentSelect(data) {
      this.hasEditedFields = true;
      this.editedFields[this.currentField.field] = data;
      //await this.fetchAllBasicUsers()
      this.currentField.value = this.getFullName(this.basicUserList.find(u => u.id === data));
    },
    async saveEdits() {
      await this.updateApartment({...this.editedFields});
      this.$message.success("Modifiche effettuate correttamente")
      this.hasEditedFields = false;
    },
    getConfirm() {
      return this.hasEditedFields
    },
    openUserHistoryDialog() {
      this.$refs.userHistoryDialog.userHistory = this.currentApartment.domesticUsers
      this.$refs.userHistoryDialog.show();
    },
    updateTenant(data) {
      if (data.operationType) {//modifica
        this.editedFields["name"] = data.name
        this.editedFields["surname"] = data.surname
        this.editedFields["renameTenant"] = true
      } else {// sostituzione
        this.editedFields["name"] = data.name
        this.editedFields["surname"] = data.surname

      }
      this.hasEditedFields = true
      this.currentField.value = data.name + " " + data.surname
    },
    createFields() {
      let year = moment().year();
      if (moment().isBefore(moment().year() + "-" + this.currentApartment.condominium.readingsEndDate)) {
        year = moment().subtract(1, 'year').year();
      }
      if (
          moment(this.currentApartment.lastReadingDate).isBefore(
              moment(year + "-" + this.currentApartment.condominium.readingsStartDate))
      ) {
        this.consumption = 0;
        this.relativeConsumption = 0
        this.targetComsumption = 0
        this.difference = 0
      } else {
        this.consumption = this.currentApartment.consumption;
        this.relativeConsumption = this.currentApartment.consumption / this.currentApartment.condominium.totalConsumption * 100;
        this.targetComsumption = this.currentApartment.kApartment / this.currentApartment.condominium.totalK * 100;
        this.difference = ((this.relativeConsumption - this.targetComsumption) / this.targetComsumption) * 100;
      }


      this.apartmentDescriptionFields = [
        {
          field: "condominium",
          value: this.stringifyAddress(this.currentApartment.condominium.address),
          label: "Indirizzo condominio - " + this.currentApartment.condominium.description,
          icon: "el-icon-location-outline",
          span: 1,
          editable: false,
        },
        {
          field: "floor",
          value: this.currentApartment.floor,
          label: "Piano/Scala",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editField,
        },
        {
          field: "number",
          value: this.currentApartment.number,
          label: "Numero",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editField,
        },
        {
          field: "consumption",
          value: this.consumption,
          label: "Consumi appartamento",
          icon: "el-icon-message",
          span: 1,
          editable: false,
        },
        {
          field: "relativeConsumption",
          value: parseFloat(this.relativeConsumption.toFixed(2)),
          label: "Consumi %",
          icon: "el-icon-message",
          span: 1,
          editable: false,
        },
        {
          field: "targetConsumption",
          value: parseFloat(this.targetComsumption.toFixed(2)),
          label: "Consumo ideale %",
          icon: "el-icon-message",
          span: 1,
          editable: false,
        },
        {
          field: "kApartment",
          value: this.currentApartment.kApartment,
          label: "Millesimi di appartamento",
          icon: "el-icon-message",
          span: 1,
          editable: true,
          onEdit: this.editK,
        },
        {
          field: "condominiumAdministrator",
          value: this.getFullName(this.currentApartment.condominium.condominiumAdministrator),
          label: "Amministratore Condominiale",
          icon: "el-icon-user",
          span: 1,
          editable: false
        },
        {
          field: "relationCode",
          value: this.currentApartment.relationCode,
          label: "Codice di relazione",
          icon: "el-icon-message",
          span: 1,
          editable: false,
        },
        {
          field: "tenant",
          value: this.getCurrentTenantName(this.currentApartment),
          label: "Utente",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editTenant,
        },
        {
          field: "description",
          value: this.currentApartment.description,
          label: "Descrizione",
          icon: "el-icon-message",
          span: 1,
          editable: true,
          onEdit: this.editField,
        },
        {
          field: "user",
          value: this.getFullName(this.currentApartment.user),
          label: "Account associato",
          icon: "el-icon-user",
          span: 1,
          editable: false,
          // onEdit: this.editUser,
        },
      ];
    }
  },
  async created() {
    const apartmentId = this.$route.params.id;
    await this.fetchApartment(apartmentId);
    if (!this.currentCondominium) {
      await this.fetchCondominium(this.currentApartment?.condominium?.id)
    }
    this.createFields()
    window.onbeforeunload = () => {
      return this.getConfirm();
    };

  },
  watch: {
    async $route(to, from) {
      if (to !== from && to.name === from.name) {
        const apartmentId = this.$route.params.id;
        if (apartmentId) {
          await this.fetchApartment(apartmentId);
          this.createFields()
        }
        if (!this.currentCondominium) {
          await this.fetchCondominium(this.currentApartment?.condominium?.id)
        }
      }
      //this.updateView = !this.updateView
    }
  }

}
</script>

<style>

.justify-space-between {
  justify-content: space-between;
}

.pointer {
  cursor: pointer;
}

.color-danger {
  color: #F56C6C
}

.color-success {
  color: #67C23A
}

.color-warning {
  color: #E6A23C
}
</style>
