<template>
  <el-dialog v-if="showDialog && isGranted('ROLE_MANAGER')" :title="$t('apartmentUserHistoryDialog.title')" v-model="showDialog" :close-on-click-modal="false">

    <div>
      <el-row justify="space-between">
        <el-col :span="8">
          <el-input placeholder="Cerca utente" v-model="query" @clear="clearInput" clearable></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col v-if="userHistory.length>0">
          <el-table
              :data="userHistory.filter( (dUser) =>
          (!query || getFullName(dUser.user).toLowerCase().includes(query.toLowerCase()))
        )">
        <el-table-column prop="startDate" label="Data Inizio">
            <template #default="scope">
              {{ formatDate(scope.row.startDate) }}
            </template>
        </el-table-column>
        <el-table-column prop="startDate" label="Data Fine">
          <template #default="scope">
            {{ formatDate(scope.row.endDate) || "...in corso" }}
          </template>
        </el-table-column>
        <el-table-column prop="user" label="Utente">
          <template #default="scope">
            {{ getFullName(scope.row.user) }}
          </template>
        </el-table-column>
<!--            <el-table-column prop="id" label="ID" width="50"></el-table-column>-->
<!--            <el-table-column prop="description" label="Denominazione"></el-table-column>-->
<!--            <el-table-column prop="address" label="Indirizzo">-->
<!--              <template #default="scope">-->
<!--                {{ stringifyAddress(scope.row.address) }}-->
<!--              </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column v-if="!condoAdmin" prop="owner" label="Cliente Sunny">-->
<!--              <template #default="scope">-->
<!--                {{ getFullName(scope.row.owner) }}-->
<!--              </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column v-if="!condoAdmin" prop="condominiumAdministrator" label="Amm. Cond.">-->
<!--              <template #default="scope">-->
<!--                {{ getFullName(scope.row.condominiumAdministrator) }}-->
<!--              </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column v-if="!condoAdmin" prop="supplier" label="Gestore Calore/Acqua">-->
<!--              <template #default="scope">-->
<!--                {{ getFullName(scope.row.supplier) }}-->
<!--              </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column v-if="!condoAdmin" prop="serviceTechnician" label="Ditta Ass. Tecn.">-->
<!--              <template #default="scope">-->
<!--                {{ getFullName(scope.row.serviceTechnician) }}-->
<!--              </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column v-if="!condoAdmin" prop="active" label="Stato" width="70">-->
<!--              <template #default="scope">-->
<!--                <i :class="getActiveIcon(scope.row.active)"></i>-->
<!--              </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column width="70">-->
<!--              <template #default="scope">-->
<!--                <el-tooltip class="item" effect="dark" content="Clicca per visualizzare la lista degli appartamenti e i consumi">-->
<!--                  <el-button @click="condominiumSelected(scope.row)" type="primary" circle size="small" icon="el-icon-d-arrow-right"> </el-button>-->
<!--                </el-tooltip>-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
        </el-col>
      </el-row>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dismiss"> {{$t("buttons.close")}}</el-button>
      </span>
    </template>

  </el-dialog>
</template>

<script>
import { mapGetters} from "vuex";
import DateMixin from "../../mixins/DateMixin";
import UserMixin from "../../mixins/UserMixin";

export default {
  name: "ApartmentUserHistoryDialog",
  components: {},
  mixins: [DateMixin, UserMixin],
  // emits: ["submit", "dismiss"],
  data() {
    return {
      showDialog: false,
      userHistory: [],
      query: "",

    }
  },
  computed: {
    ...mapGetters(["isGranted"]),
  },
  methods: {
    // ...mapActions(["registerUser", "fetchAllHomelessUsers"]),
    show() {
      this.showDialog = true;
    },
    // submit() {
    //   this.$emit("submit", this.formData);
    //   this.showDialog = false;
    // },
    dismiss() {
      // this.$emit("dismiss");
      this.showDialog = false;
    },
    clearInput(){
      this.query = "";
    }

  }
}
</script>

<style scoped>

</style>