<template>
  <el-dialog v-if="showDialog" title="Cambia Utente" v-model="showDialog" :close-on-click-modal="false">
    <el-form label-width="auto">
      <el-form-item  label="">
        <el-switch v-model="operationType" @change="toggleOperationType" 
          active-text="Modifica"
          inactive-text="Sostituisci"
        ></el-switch>
      </el-form-item>
      <el-form-item  label="Nome">
        <el-input v-model="name" type="text"></el-input>
      </el-form-item>
      <el-form-item label="Cognome">
        <el-input v-model="surname" type="text"></el-input>
      </el-form-item>
    </el-form>
    <!-- <el-divider> oppure  </el-divider>
    <el-button type="primary" icon="el-icon-plus" @click="$refs.createUserDialog.show()">{{$t("buttons.user.create")}}</el-button> -->

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dismiss">Annulla</el-button>
        <el-button type="primary" @click="submit">Conferma</el-button>
      </span>
    </template>
<!-- 
    <form-dialog :title="'Crea Utente'" :fields="newUserFields" @submit="submittedCreateUser"
                 ref="createUserDialog"></form-dialog> -->
  </el-dialog>




</template>

<script>
// import FormDialog from "./FormDialog";
import {mapActions, mapGetters} from "vuex";
import UserMixin from "../../mixins/UserMixin";
import UtilsMixin from "../../mixins/UtilsMixin";

export default {
  name: "ChangeTenantDialog",
  mixins: [UserMixin, UtilsMixin],
  emits: ["submit", "dismiss"],
  data() {
    return {
      name: "",
      surname: "",
      currentTenant: null,
      showDialog: false,
      title: "",
      operationType: true
    }
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions([]),
    show(){
      this.toggleOperationType()
      this.showDialog = true;
    },
    submit() {
      this.$emit("submit", {
        name: this.name,
        surname: this.surname,
        operationType: this.operationType
      });
      this.showDialog = false;
    },
    dismiss() {
      this.$emit("dismiss");
      this.showDialog = false;
    },
    toggleOperationType(){
      if (this.operationType){
        this.name = this.currentTenant?.name ?? ""
        this.surname = this.currentTenant?.surname ?? ""
      } else {
        this.name = ""
        this.surname = ""
      }
    }
    
  },
  async mounted() {
    
  }

}
</script>

<style scoped>

</style>

